<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div v-else>
        <back-navigation title="Detail Sales Order" />

        <b-row>
          <b-col
            cols="12"
            lg="8"
            class="order-2 order-lg-1"
          >
            <XyzTransition
              appear
              xyz="fade left-3 delay-3"
            >
              <div>
                <b-card>
                  <div class="d-flex justify-content-between align-items-start">
                    <h4 class="text-muted font-weight-normal">
                      {{ salesData.number }}
                    </h4>
                    <b-badge
                      :variant="getSalesOrderStatusVariant(salesData.status)"
                    >
                      {{ salesData.status }}
                    </b-badge>
                  </div>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <small class="text-muted">
                        Tanggal Pengiriman
                      </small>
                      <h6>
                        {{ salesData.shipDate ? salesData.shipDate : '-' }}
                      </h6>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <small class="text-muted">
                        Syarat Pembayaran
                      </small>
                      <h6>
                        {{ salesData.paymentTerm ? salesData.paymentTerm.name : '-' }}
                      </h6>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <small class="text-muted">
                        Alamat Pengiriman
                      </small>
                      <h6>
                        {{ salesData.toAddress ? salesData.toAddress : '-' }}
                      </h6>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <small class="text-muted">
                        Keterangan
                      </small>
                      <h6>
                        {{ salesData.description ? salesData.description : '-' }}
                      </h6>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <small class="text-muted">
                        PO Number
                      </small>
                      <h6>
                        {{ salesData.poNumber ? salesData.poNumber : '-' }}
                      </h6>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <small class="text-muted">
                        Nama Pengiriman
                      </small>
                      <h6>
                        {{ salesData.shipment ? salesData.shipment.name : '-' }}
                      </h6>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card>
                  <vue-good-table
                    :columns="itemTableColumn"
                    :rows="salesData.items"
                    :pagination-options="{
                      enabled: false,
                    }"
                    style-class="vgt-table"
                  >
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'name'">
                        <h6 class="m-0">
                          {{ props.row.name }}
                        </h6>
                        <small>
                          {{ props.row.no }}
                        </small>
                      </span>
                      <span v-if="props.column.field === 'quantity'">
                        <h6 class="m-0">
                          {{ props.row.quantity ? `${props.row.quantity} L` : '0 L' }}
                        </h6>
                      </span>
                      <span v-if="props.column.field === 'unitPrice'">
                        <h6 class="m-0">
                          {{ props.row.unitPrice ? formatCurrency(props.row.unitPrice) : 0 }}
                        </h6>
                      </span>
                      <span v-if="props.column.field === 'itemCashDiscount'">
                        <h6 class="m-0">
                          {{ props.row.itemCashDiscount ? formatCurrency(props.row.itemCashDiscount) : 0 }}
                        </h6>
                      </span>
                      <span v-if="props.column.field === 'totalPrice'">
                        <h6 class="m-0">
                          {{ props.row.totalPrice ? formatCurrency(props.row.totalPrice) : 0 }}
                        </h6>
                      </span>
                    </template>
                  </vue-good-table>
                </b-card>
              </div>
            </XyzTransition>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="order-1 order-lg-2"
          >
            <sticky-panel>
              <XyzTransition
                appear
                xyz="fade right-3 delay-5"
              >
                <b-card>
                  <b-tabs>
                    <b-tab title="Detail">
                      <small class="text-muted">
                        Tanggal Transaksi
                      </small>
                      <h6>
                        {{ salesData.transDate ? salesData.transDate : '-' }}
                      </h6>
                      <small class="text-muted">
                        Customer
                      </small>
                      <router-link
                        :to="{ name: 'customer-detail', params: { id: salesData.customer.id.toString() } }"
                        target="_blank"
                      >
                        <h6 class="text-primary">
                          {{ salesData.customer.name }}
                        </h6>
                      </router-link>
                      <hr>
                      <div class="d-flex justify-content-between mt-1 align-items-center">
                        <small class="font-weight-bolder">Subtotal</small>
                        <h6 class="font-weight-normal m-0">
                          {{ formatCurrency(salesData.subTotal) }}
                        </h6>
                      </div>
                      <div
                        v-if="salesData.cashDiscount"
                        class="d-flex justify-content-between mt-1 align-items-center"
                      >
                        <small class="font-weight-bolder">Diskon</small>
                        <h6 class="font-weight-normal m-0">
                          {{ `- ${formatCurrency(salesData.cashDiscount)}` }}
                        </h6>
                      </div>
                      <div
                        v-if="salesData.tax1Amount"
                        class="d-flex justify-content-between mt-1 align-items-center"
                      >
                        <small class="font-weight-bolder">Pajak</small>
                        <h6 class="font-weight-normal m-0">
                          {{ formatCurrency(salesData.tax1Amount) }}
                        </h6>
                      </div>
                      <div class="d-flex justify-content-between mt-1 align-items-center">
                        <small class="font-weight-bolder">Total</small>
                        <h5 class="font-weight-bolder text-primary m-0">
                          {{ formatCurrency(salesData.totalAmount) }}
                        </h5>
                      </div>
                      <hr>
                      <b-button
                        v-if="salesData.status === 'DRAFT'"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        class="mt-1"
                        @click="submitSalesOrder()"
                      >
                        Submit
                      </b-button>
                      <hr v-if="salesData.status === 'DRAFT'">
                      <b-button
                        v-if="$root.role === 2 || (salesData.userCreate && salesData.userCreate.id === this.$store.getters.getCurrentUser.user.id)"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        block
                        class="mt-1"
                        @click="$router.push({ name: 'salesorder-edit', params: { id: +id } })"
                      >
                        Edit
                      </b-button>
                      <b-button
                        v-if="$root.role === 2 || (salesData.userCreate && salesData.userCreate.id === this.$store.getters.getCurrentUser.user.id)"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        block
                        class="mt-1"
                        @click="$root.role === 2 || salesData.id === $store.getters.getCurrentUser.user.id ? deleteSalesOrder() : null"
                      >
                        Hapus
                      </b-button>
                    </b-tab>
                    <b-tab
                      v-if="salesData.attachment && salesData.attachment.length"
                      title="Attachment"
                    >
                      <div
                        v-for="(file, index) in salesData.attachment"
                        :key="file.name + index"
                        class="custom-attachment p-75 mb-1 cursor-pointer"
                        @click="handleAttachmentClick(file)"
                      >
                        <small class="font-weight-bold d-block text-primary">
                          {{ file.name }}
                        </small>
                        <small class="text-muted">
                          {{ file.filesizeInMega }}
                        </small>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </XyzTransition>
            </sticky-panel>
          </b-col>
        </b-row>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import {
  BCard, BRow, BCol, BButton, BSpinner, BTabs, BTab, BBadge,
} from 'bootstrap-vue'
import StickyPanel from '@/components/misc/StickyPanel.vue'
import { VueGoodTable } from 'vue-good-table'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import { formatCurrency } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTabs,
    BTab,
    BBadge,
    BackNavigation,
    StickyPanel,
    VueGoodTable,
    ErrorEndpoint,
  },
  props: {
    id: {
      required: true,
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      error: '',
      salesData: '',
      itemTableColumn: [
        {
          label: 'Item',
          field: 'name',
          sortable: false,
          width: '180px',
        },
        {
          label: 'Volume',
          field: 'quantity',
          sortable: false,
          width: '120px',
        },
        {
          label: 'Harga',
          field: 'unitPrice',
          sortable: false,
          width: '160px',
        },
        {
          label: 'Diskon',
          field: 'itemCashDiscount',
          sortable: false,
          width: '200px',
        },
        {
          label: 'Subtotal',
          field: 'totalPrice',
          sortable: false,
          width: '160px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      salesOrderStatusOptions: 'getSalesOrderStatusOptions',
    }),
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    formatCurrency,
    fetchDetail() {
      this.$store.dispatch('getSalesOrderDetail', +this.id).then(result => {
        this.loading = false
        this.salesData = result
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    handleAttachmentClick(file) {
      window.open(file.path, '_blank')
    },
    deleteSalesOrder() {
      this.$swal({
        title: 'Hapus Sales Order?',
        text: 'Konfirmasi jika anda ingin menghapus data sales order',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteSalesOrder', +this.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data sales order!',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })

            this.$router.replace('/salesorder')
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Gagal menghapus data. ${err.graphQLErrors[0].message}`,
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    getSalesOrderStatusVariant(status) {
      const filter = this.salesOrderStatusOptions.filter(el => el.label === status)

      if (!filter.length) {
        return 'secondary'
      }

      return filter[0].variant
    },
    submitSalesOrder() {
      this.$swal({
        title: 'Submit sales order?',
        text: 'Konfirmasi jika anda ingin mensubmit sales order',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.push({ name: 'salesorder-edit', params: { id: +this.id, submit: true } })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.custom-attachment {
  border-radius: 8px;
  border: 2px solid rgb(223, 223, 223);
}
</style>
